import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from '../views/login.vue';
import RegisterPage from '../views/regeist.vue';
import HomePage from '../views/home/index.vue';
import AgreementPage from '../views/agreement.vue';
import RecordsPage from '../views/records.vue';
import TcPage from '../views/tc.vue';
import EventPage from '../views/event.vue';
import FaqPage from '../views/faq.vue';
import CertPage from '../views/cert.vue';
import AboutPage from '../views/about.vue';
import VipLevelPage from '../views/vipLevel.vue';
import MyInfoPage from '../views/myInfo.vue';
import DepositPage from '../views/deposit.vue';
import RechargeHistoryPage from '../views/rechargeHistory.vue';
import WithdrawPage from '../views/withdraw.vue';
import WithdrawHistoryPage from '../views/withdrawHistory.vue';
import TransactionPage from '../views/transaction.vue';
import ChangePwdPage from '../views/changePwd.vue';
import ChangePayPwdPage from '../views/changePayPwd.vue';
import ChangeFullNamePage from '../views/changeFullName.vue';
import ChangePhonePage from '../views/changePhone.vue';

Vue.use(VueRouter);

const routes = [{
		path: '/',
		name: 'main',
		redirect: '/login/login',
		component: HomePage,
		children: [{
			path: 'home/home',
			name: 'home',
			component: HomePage,
			meta: {
				name: 'Home',
			},
		}, ],
	},
	{
		path: '/login/login',
		name: 'login',
		component: LoginPage,
		meta: {
			name: 'Login',
		},
	},
	{
		path: '/regeist/regeist',
		name: 'regeist',
		component: RegisterPage,
		meta: {
			name: 'Register',
		},
	},

	{
		path: '/agreement/agreement',
		name: 'agreement',
		component: AgreementPage,
		meta: {
			name: 'Agreement',
		},
	},
	{
		path: '/records/records',
		name: 'records',
		component: RecordsPage,
		meta: {
			name: 'Records',
		},
	},
	{
		path: '/tc/tc',
		name: 'tc',
		component: TcPage,
		meta: {
			name: 'Terms And Conditions',
		},
	},
	{
		path: '/event/event',
		name: 'event',
		component: EventPage,
		meta: {
			name: 'Event',
		},
	},
	{
		path: '/faq/faq',
		name: 'faq',
		component: FaqPage,
		meta: {
			name: 'FAQ',
		},
	},
	{
		path: '/cert/cert',
		name: 'cert',
		component: CertPage,
		meta: {
			name: 'Certificate',
		},
	},
	{
		path: '/about/about',
		name: 'about',
		component: AboutPage,
		meta: {
			name: 'About Us',
		},
	},
	{
		path: '/vipLevel/vipLevel',
		name: 'vipLevel',
		component: VipLevelPage,
		meta: {
			name: 'VipLevel',
		},
	},
	{
		path: '/myInfo/myInfo',
		name: 'myInfo',
		component: MyInfoPage,
		meta: {
			name: 'MyInfo',
		},
	},
	{
		path: '/deposit/deposit',
		name: 'deposit',
		component: DepositPage,
		meta: {
			name: 'Deposit',
		},
	},
	{
		path: '/rechargeHistory/rechargeHistory',
		name: 'rechargeHistory',
		component: RechargeHistoryPage,
		meta: {
			name: 'RechargeHistory',
		},
	},
	{
		path: '/withdraw/withdraw',
		name: 'withdraw',
		component: WithdrawPage,
		meta: {
			name: 'Withdraw',
		},
	},
	{
		path: '/withdrawHistory/withdrawHistory',
		name: 'withdrawHistory',
		component: WithdrawHistoryPage,
		meta: {
			name: 'WithdrawHistory',
		},
	},
	{
		path: '/transaction/transaction',
		name: 'transaction',
		component: TransactionPage,
		meta: {
			name: 'Transaction',
		},
	},
	{
		path: '/changePwd/changePwd',
		name: 'changePwd',
		component: ChangePwdPage,
		meta: {
			name: 'ChangePwd',
		},
	},
	{
		path: '/changePayPwd/changePayPwd',
		name: 'changePayPwd',
		component: ChangePayPwdPage,
		meta: {
			name: 'ChangePayPwd',
		},
	},
	{
		path: '/changeFullName/changeFullName',
		name: 'changeFullName',
		component: ChangeFullNamePage,
		meta: {
			name: 'ChangeFullName',
		},
	},
	{
		path: '/changePhone/changePhone',
		name: 'changePhone',
		component: ChangePhonePage,
		meta: {
			name: 'ChangePhone',
		},
	},
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
});

export default router;